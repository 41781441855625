import React, { useEffect } from "react";
import { styled } from "@material-ui/core";
import { EmailList } from "./CommonTypes";
import { Avatar } from "../../components/src";
interface SelectEmailDropdownProps {
    emails: EmailList[];
    onSelect: (email: EmailList) => void;
    alreadySelected?: (id: number)=> void;
    onClose?: ()=>void;
    parentId?: string;
    searchText: string;
}


const SelectEmailDropdown: React.FC<SelectEmailDropdownProps> = (props: SelectEmailDropdownProps) => {
    const { emails, onSelect, alreadySelected, onClose, parentId = "", searchText } = props;

    const emailSelected = (id: number)=>{
       if(alreadySelected){
        return alreadySelected(id);
       }
       return false;
    }
    const handleClickOutside = (event: MouseEvent) => {
        const menu = document.getElementById(parentId);
        if (onClose && menu && !menu.contains(event.target as Node)) {
            onClose();
        }
    }
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true)
        }
    }, [])
    return (
        <StyledSelectEmailDropdown data-test-id="email-list-menu" className="SelectEmailDropdown_StyledSelectEmailDropdown">
            {
                emails && emails.length > 0 ? emails.map(email => {
                    return (
                        <div key={email.id} className={`email-container ${emailSelected(email.id) ? "selected": ""}`} onClick={() => {
                            if(!emailSelected(email.id)){
                                onSelect(email)
                            }
                        }}>
                            <Avatar 
                                text={email.full_name}
                                image_url={email.image_url}
                            />
                            <div className="email">
                                <p>{email.full_name}</p>
                                <p>{email.email}</p>
                            </div>
                        </div>
                    )
                }) : 
                <>
                {searchText.trim() && <p>No data found</p>}
                </>
            }
        </StyledSelectEmailDropdown>
    )
}

const StyledSelectEmailDropdown = styled("div")({
    "&.SelectEmailDropdown_StyledSelectEmailDropdown": {
        position: "absolute",
        left: 0,
        top: "100%",
        right: 0,
        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        border: "1px solid",
        borderImageSource: "linear-gradient(0deg, rgba(28, 150, 154, 0.11) 0%, rgba(25, 181, 185, 0.27) 100%)",
        boxShadow: "0px 0px 30px 0px #00000080",
        width: "95%",
        borderRadius: "0.5rem",
        padding: "1rem 0.5rem",
        zIndex: 2,
        maxHeight: "10rem",
        overflowY: "auto",
        "& .email-container": {
            display: "flex",
            gap: "0.5rem",
            alignItems: "center",
            color: "#fff",
            fontSize: "0.75rem",
            marginBottom: "1rem",
            cursor: "pointer",
            "& .avatar": {
                width: "2rem",
                height: "2rem",
                borderRadius: "100%",
                border: "1px solid #26EFF5",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#26EFF5",
                fontSize: "0.875rem",
                textTransform: "capitalize"
            },
            "&:last-child": {
                marginBottom: 0
            },
            "& .email": {
                display: "flex",
                flexDirection: "column",
                "& p": {
                    margin: 0,
                    color: "white",
                    fontSize: "0.875rem",
                    fontWeight: "400",
                    textAlign: "left",
                    "&:last-child": {
                        fontSize: "0.75rem",
                        color: "#FFFFFFCC"
                    }
                }
            },
            "&.selected": {
                opacity: 0.5,
                cursor: "not-allowed"
            }
        },
        "& p": {
            margin: 0,
            fontSize: "0.875rem",
            textAlign: "center",
            color: "#fff"
        }
    }
})


export default SelectEmailDropdown;