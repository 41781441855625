import React from "react";
import { styled} from "@material-ui/styles";
import { EditIcon, DeleteIcon } from "../assets";
import { IconButton, Box, Grid, Typography } from "@material-ui/core";
import { IReminderItem, IReminders } from "../../../tasks/src/types";

interface DashboardReminderCardProps {
    reminder: IReminders;
    redirectTo: (endpoint: string, params?: {[key:string]: string})=> void;
    onDelete: (id?: string, title?: string)=> void;
    openReminderDetailsPopup?: (details: IReminderItem)=> void; 
}

const DashboardReminderCard: React.FC<DashboardReminderCardProps> = (props: DashboardReminderCardProps) => {
    const { reminder, redirectTo, onDelete, openReminderDetailsPopup } = props;
    const goToEditPage = (reminderId: string)=>{
        redirectTo("EditReminder", { id: reminderId })
    }
    return (
        <StyledDashboardReminderCard className="DashboardReminderCard_StyledDashboardReminderCard_Container">
           <header>{reminder.isToday ? `Today / ${reminder.desktop_date.split(' ')[1]}` : reminder.formatted_date}</header>
           <div className="reminders-for-particular-date">
              {
                reminder.reminders.map((reminderData, remInd)=> {
                    return (
                        <>
                            <div className={`reminder-card ${reminderData.attributes.reminder_status}`} key={remInd}>
                                <Grid className="reminder-details details" item>
                                            <Box mb={"4px"}>
                                                <Typography onClick={()=> {
                                        if(reminderData.attributes.reminder_status !== "completed" && openReminderDetailsPopup){
                                            openReminderDetailsPopup(reminderData)
                                        }}} className={`reminder-title cursor-pointer capital-text`} component={"span"}>
                                                {reminderData.attributes.title}
                                                </Typography>
                                            </Box>
                                            <Box mb={"4px"} className="ellipsis" style={{display: "flex", width: "100%"}}>
                                                <Typography className="reminder-desc" style={{display: "flex", width: "100%"}} component={"span"}>
                                                {reminderData.attributes.description}
                                                    
                                                </Typography>
                                            </Box>
                                            <Box display={"flex"} >

                                                <Box mr={"5px"} >
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <mask id="mask0_18738_114703" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
                                                        <rect width="16" height="16" fill={reminderData.attributes.lapsed_status ? "rgba(255, 0, 0, 1)" : "#D9D9D9"} />
                                                    </mask>
                                                    <path d="M10.3147 11.0153L11.0173 10.3128L8.49932 7.79466V4.66405H7.49935V8.19995L10.3147 11.0153ZM8.00045 14.3307C7.12449 14.3307 6.30114 14.1645 5.53038 13.832C4.75962 13.4996 4.08916 13.0484 3.51902 12.4785C2.94886 11.9086 2.49749 11.2385 2.1649 10.468C1.83231 9.69763 1.66602 8.87445 1.66602 7.9985C1.66602 7.12254 1.83224 6.29918 2.16468 5.52843C2.49713 4.75766 2.94829 4.08721 3.51818 3.51706C4.08808 2.94691 4.75824 2.49554 5.52867 2.16295C6.29908 1.83036 7.12226 1.66406 7.99822 1.66406C8.87417 1.66406 9.69753 1.83029 10.4683 2.16273C11.239 2.49517 11.9095 2.94634 12.4796 3.51623C13.0498 4.08613 13.5012 4.75629 13.8338 5.52671C14.1664 6.29712 14.3327 7.12031 14.3327 7.99626C14.3327 8.87222 14.1664 9.69557 13.834 10.4663C13.5015 11.2371 13.0504 11.9076 12.4805 12.4777C11.9106 13.0479 11.2404 13.4992 10.47 13.8318C9.69959 14.1644 8.8764 14.3307 8.00045 14.3307ZM7.99933 13.3307C9.47711 13.3307 10.7354 12.8113 11.7743 11.7724C12.8132 10.7335 13.3327 9.47516 13.3327 7.99738C13.3327 6.5196 12.8132 5.26127 11.7743 4.22238C10.7354 3.18349 9.47711 2.66405 7.99933 2.66405C6.52155 2.66405 5.26322 3.18349 4.22433 4.22238C3.18544 5.26127 2.666 6.5196 2.666 7.99738C2.666 9.47516 3.18544 10.7335 4.22433 11.7724C5.26322 12.8113 6.52155 13.3307 7.99933 13.3307Z" fill={reminderData.attributes.lapsed_status ? "rgba(255, 0, 0, 1)" : "#D9D9D9"} fillOpacity="0.8" />
                                                </svg>
                                                </Box>
                                                <Typography className={`reminder-time ${reminderData.attributes.lapsed_status ? "time-red" : ""}`}>
                                                    {reminderData.attributes.time_reminder}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                <div className="actions">
                                    <div>
                                        {
                                            !reminderData.attributes.lapsed_status && (
                                                <IconButton style={{ padding: "6px" }} onClick={(event) => {
                                                    event.stopPropagation();
                                                    if (reminderData.attributes.reminder_status !== "completed") {
                                                        goToEditPage(`${reminderData.id}`)
                                                    }
                                                }}>
                                                    <img src={EditIcon} />
                                                </IconButton>
                                            )
                                        }
                                    </div>
                                    <div>
                                        {
                                            !reminderData.attributes.lapsed_status && (
                                                <IconButton style={{ padding: "6px" }} onClick={(event) => {
                                                    event.stopPropagation();
                                                    if (reminderData.attributes.reminder_status !== "completed") {
                                                        onDelete(`${reminderData.id}`, reminderData.attributes.title)
                                                    }
                                                }}>
                                                    <img src={DeleteIcon} />
                                                </IconButton>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                })
              }
           </div>
        </StyledDashboardReminderCard>
    )
}

const StyledDashboardReminderCard = styled("div")({
    "&.DashboardReminderCard_StyledDashboardReminderCard_Container": {
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
        "& > header": {
            color: "rgba(255, 255, 255, 0.8)",
            fontSize: "0.875rem",
            fontWeight: 400
        },
        "& > .reminders-for-particular-date": {
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            "& > .reminder-card":{
                background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
                border: "1px solid rgba(25, 181, 185, 0.27)",
                height: "5.5625rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "1rem",
                width: "100%",
                padding: "1rem",
                boxSizing: "border-box",
                overflow: "hidden",
                "& .reminder-details": {
                width: "85%",
                paddingLeft: "12px",
            "& .reminder-title": {
                fontSize: "18px",
                fontWeight: 500,
                color: "rgba(255, 255, 255, 0.8)"
            },
            "& .reminder-desc": {
                fontSize: "12px",
                fontWeight: 400,
                color: "rgba(255, 255, 255, 0.8)",
            },
            "& .reminder-time": {
                fontSize: "14px",
                fontWeight: 400,
                color: "rgba(255, 255, 255, 0.8)"
            },
            "& .time-red": {
                color: "rgba(255, 0, 0, 1)"
            },
            "& .time-regular": {
                color: "rgba(255, 255, 255, 0.8)",
            },
            [`@media screen and (max-width: 950px)`]: {
                width: "75%"
            },
        },
                "& > .actions":{
                    height: "100%",
                    display: "flex",
                    gap: "0.5rem"
                },
                
                "&.completed":{
                    opacity: 0.5,
                    "& > .details":{
                        "& > .title":{
                            cursor: "not-allowed"
                        }
                    },
                    "& > .actions button":{
                        cursor: "not-allowed"
                    }
                }
            }
        }
    }
})

export default DashboardReminderCard;