import React, { useEffect, useState, useRef, useMemo } from "react";
import { styled, Modal } from "@material-ui/core";
const { useInView } = require("react-intersection-observer");
import { ContactList } from "./CommonTypes";
import { closeIcon, searchIcon, checkedBoxIcon, emptyCheckBoxIcon } from "../../blocks/appointmentmanagement/src/assets";
import { LessIcon } from "../../blocks/chat/src/assets";
import ModalLoader from "./ModalLoader.web";
import _ from "lodash";
import { Avatar } from "../../components/src";
interface SelectContactsProps {
    contacts: ContactList[];
    phoneBookContacts?: ContactList[];
    filterContacts: ContactList[]
    open: boolean;
    onClose: () => void;
    onSearch: (event: string) => void;
    onAdd: (contacts: ContactList[]) => void;
    alreadySelected?: (id: number | string) => void;
    enableBack?: boolean;
    onClickBack?: () => void;
    header?: string;
    loading?: boolean;
    fetchRegisteredContacts?: (event?: any) => void,
    hasMoreRegisteredContacts?: boolean;
    alwaysEnableAddButton?: boolean;
}


const SelectContacts: React.FC<SelectContactsProps> = (props: SelectContactsProps) => {
    const {
        contacts,
        open,
        onClose,
        onSearch,
        onAdd,
        alreadySelected,
        filterContacts,
        enableBack,
        onClickBack,
        header = "Select Contact",
        loading,
        phoneBookContacts = [],
        fetchRegisteredContacts,
        hasMoreRegisteredContacts,
        alwaysEnableAddButton
    } = props;
    const [selected, setSelected] = useState<ContactList[]>([])
    const [allSelected, setAllSelected] = useState<boolean>(false)
    const [activeTab, setActiveTab] = useState(0);
    const [search, setSearch] = useState("");

    const [loading2, setLoading2] = useState(false)
    const [endOfContacts, isEndOfContactsVisible] = useInView({
        threshold: 0,
    });
    const onSelectContact = (contact: ContactList) => {
        setSelected(prevState => ([...prevState, contact]));
    }

    const onRemoveContact = (contactId: number) => {
        const newContacts: ContactList[] = selected.filter(contact => contact.id !== contactId);
        setSelected(newContacts);
    }

    const unselectAll = () => {
        const currentContacts = activeTab === 0 ? contacts : phoneBookContacts;

        const currentContactIds = currentContacts.map(contact => contact.id);

        let updatedSelected = selected.filter(
            selectedContact => !currentContactIds.includes(selectedContact.id)
        );
        if(activeTab === 0) {
            updatedSelected = updatedSelected.filter(item => item.isNonRegistered);
        } else {
            updatedSelected = updatedSelected.filter(item => !item.isNonRegistered);
        }
        setSelected(updatedSelected);
    }

    const selectAllContacts = () => {
        if (isAllContactSelected()) {
            unselectAll()
        } else {
            if (activeTab === 0) {
                setSelected(prevState => (_.uniqBy([...prevState, ...contacts.filter(contact => !contactSelected(contact.full_phone_number) && !contactSelected(contact.id))], "id")));
            } else {
                setSelected(prevState => (_.uniqBy([...prevState, ...phoneBookContacts.filter(contact => !contactSelected(contact.full_phone_number) && !contactSelected(contact.id))], "id")));
            }
        }
        setAllSelected(prevState => !prevState);
    }

    const isContactSelected = (contactId: number) => {
        if (selected.find(item => item.id.toString() === contactId.toString())) {
            return true;
        }
        return false;
    }

    const onAddHandler = () => {
        onAdd(selected)
        setSelected([])
        setAllSelected(false)
    }

    const onCloseHandler = () => {
        onClose();
        setSelected([])
        setAllSelected(false)
    }

    const contactSelected = (id: number | string) => {
        if (alreadySelected) {
            return alreadySelected(id);
        }
        return false;
    }

    const getContacts = () => {
        if (activeTab === 0) return contacts;
        return phoneBookContacts;
    }

    const sortedContacts = useMemo(() => {
        const currentTabContacts = activeTab === 0 ? contacts.filter(
            contact => !selected.some(selectedContact => selectedContact.id === contact.id)
        ) : phoneBookContacts.filter(
            contact => !selected.some(selectedContact => selectedContact.id === contact.id)
        );
        
        const selectedContacts = activeTab === 0 ? selected.filter(selectedContact => !selectedContact.isNonRegistered) : selected.filter(selectedContact => selectedContact.isNonRegistered);
      
        const unselectedContacts = currentTabContacts.filter(
            contact => !selected.some(selectedContact => selectedContact.id === contact.id)
        );
        // Combine selected and unselected while keeping their original order
        return [...selectedContacts, ...unselectedContacts];
      }, [contacts, phoneBookContacts, selected, activeTab]);

    const changeTabs = (tab: number) => {
        setActiveTab(tab);
        setAllSelected(false);
        setSearch("");
        onSearch("");
    }

    const isAllContactSelected = () => {
        let allSelected = true;
        getContacts().forEach(contact => {
            if (!selected.find(item => item.full_phone_number === contact.full_phone_number)) {
                allSelected = false;
                return;
            }
        })
        return allSelected;
    }

    useEffect(() => {
        const fetchContacts = async () => {
            if (isEndOfContactsVisible && !loading2 && hasMoreRegisteredContacts && activeTab === 0 && !search) {
                setLoading2(true);
                fetchRegisteredContacts?.();
                setLoading2(false);
            }
        };
        fetchContacts();
    }, [isEndOfContactsVisible, loading2, hasMoreRegisteredContacts, activeTab, search])

    useEffect(() => {
        setSelected(filterContacts)
    }, [])

    return (
        <Modal data-test-id="contact-list-modal" open={open} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <StyledSelectContacts className="SelectContacts_StyledSelectContacts">
                <div className="header">
                    {
                        enableBack && (
                            <img className="back" src={LessIcon} onClick={onClickBack} />
                        )
                    }
                    <p>{header}</p>
                    <img className="close" src={closeIcon} alt="close.svg" onClick={onCloseHandler} />
                </div>
                <div className="body" id="body">
                    <div className="filter-tabs">
                        <div
                            className={`tab ${activeTab === 0 ? "active" : ""}`}
                            onClick={() => changeTabs(0)}
                        >
                            Registered Contacts
                        </div>
                        <div
                            className={`tab ${activeTab === 1 ? "active" : ""}`}
                            onClick={() => changeTabs(1)}
                        >
                            Phonebook Contacts
                        </div>
                    </div>
                    <div className="search-container">
                        <img src={searchIcon} alt="search.svg" />
                        <input value={search} placeholder="Search name or number" data-test-id="add-contact-modal" onChange={(event) => {
                            setSearch(event.target.value)
                            onSearch(event.target.value)
                            }} />
                    </div>
                    <div className="contact-list-container">
                        <div className="contact-header">
                            {sortedContacts.length > 0 && <p>Contacts</p>}
                            {
                                _.uniqBy(sortedContacts, "id").length > 0 && (
                                    <p onClick={selectAllContacts} data-test-id="select-all-contact">{isAllContactSelected() ? "Unselect All" : "Select All"}</p>
                                )
                            }
                        </div>
                        <div
                            className="contacts"
                            data-test-id="contact-list"
                            id="contacts"
                        >
                            {
                                sortedContacts.length > 0 && _.uniqBy(sortedContacts, "id").map((contact, contactIndex) => {
                                    console.log(contact.image_url, "===@@@")
                                    return (
                                        <div
                                            ref={contactIndex === sortedContacts.length - 1 ? endOfContacts : null}
                                            className={`contact ${contactSelected(contact.id) || contactSelected(contact.full_phone_number) ? "selected" : ""}`}
                                            key={`${contact.id}-${contact.full_phone_number}`}
                                        >
                                            <div className="name">
                                                <Avatar 
                                                    text={contact.name}
                                                    image_url={contact.image_url}
                                                    />
                                                <div className="user-contact-full-name capital-text">{contact.name}</div>
                                            </div>
                                            {
                                                !isContactSelected(contact.id as any) && (
                                                    <img
                                                        src={emptyCheckBoxIcon}
                                                        onClick={() => {
                                                            if (!contactSelected(contact.id) && !contactSelected(contact.full_phone_number)) {
                                                                onSelectContact(contact)
                                                            }
                                                        }}
                                                    />
                                                )
                                            }
                                            {
                                                isContactSelected(contact.id as any) && (
                                                    <img
                                                        src={checkedBoxIcon}
                                                        onClick={() => {
                                                            onRemoveContact(contact.id as any)
                                                        }}
                                                    />
                                                )
                                            }
                                        </div>
                                    )
                                })
                            }
                            {
                                contacts.length === 0 && !loading && (
                                    <div id="no-data" className="no-data-found">{activeTab === 0 ? "No registered contacts found" : "No phonebook contacts found"}</div>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <button onClick={onAddHandler} disabled={selected.length === 0 && !alwaysEnableAddButton}>
                        Add
                    </button>
                </div>
                <ModalLoader isLoading={loading} />
            </StyledSelectContacts>
        </Modal>
    )
}

const StyledSelectContacts = styled("div")({
    "&.SelectContacts_StyledSelectContacts": {
        display: "flex",
        flexDirection: "column",
        width: "26.25rem",
        height: "34.3125rem",
        backgroundColor: "#23404B",
        boxShadow: "0px 0px 20px 0px #00000040",
        borderRadius: "1rem",
        position: "relative",
        "& .header": {
            position: "relative",
            padding: "1rem",
            borderBottom: "1px solid #2d4953",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "& p": {
                margin: 0,
                fontSize: "1.125rem",
                fontWeight: 500,
                color: "white"
            },
            "& > img.close": {
                position: "absolute",
                right: "1rem",
                top: "50%",
                transform: "translate(-50%,-50%)",
                cursor: "pointer"
            },
            "& > img.back": {
                position: "absolute",
                left: "1rem",
                top: "50%",
                transform: "translate(50%,-50%)",
                cursor: "pointer"
            }
        },
        "& .body": {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            // overflowX: "auto",
            gap: "1rem",
            padding: "1rem 1rem 0 1rem",
            height: "calc(100% - 10.75rem)",
            "& > .filter-tabs": {
                borderRadius: "3.125rem",
                background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
                border: "1px solid rgba(25, 181, 185, 0.27)",
                padding: "0rem 0.5rem",
                display: "flex",
                height: "2.5rem",
                flexShrink: 0,
                justifyContent: "space-between",
                alignItems: "center",
                boxSizing: "border-box",
                "& > .tab": {
                    fontSize: "0.875rem",
                    fontWeight: 400,
                    color: "rgba(255, 255, 255, 0.5)",
                    height: "1.75rem",
                    padding: "0rem 0.75rem",
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    borderRadius: "3.125rem",
                    "&.active": {
                        fontSize: "0.875rem",
                        fontWeight: 500,
                        color: "rgba(255, 255, 255, 1)",
                        background: "linear-gradient(90deg, #FF7F77 0%, #FF6376 100%)",
                    }
                }
            },
            "& .tab-container": {
                display: "flex",
                justifyContent: "center",
                "& .tab": {
                    background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
                    width: "13.5rem",
                    height: "2.25rem",
                    borderRadius: "3.125rem",
                    display: "flex",
                    padding: "0rem 0.3rem",
                    justifyContent: "space-between",
                    alignItems: "center",
                    "& > div": {
                        width: "7rem",
                        height: "1.75rem",
                        borderRadius: "3.125rem",
                        fontSize: "0.875rem",
                        fontWeight: 400,
                        color: "white",
                        opacity: 0.5,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        transition: "all 0.3s ease-in-out",
                        "&.active": {
                            background: "linear-gradient(90deg, #FF7F77 0%, #FF6376 100%)",
                            opacity: 1,
                            fontWeight: 500
                        }
                    }
                }
            },
            "& .search-container": {
                display: "flex",
                backgroundColor: "#00000026",
                gap: "0.5rem",
                borderRadius: "6.25rem",
                padding: "0.75rem 1rem",
                "& input": {
                    outline: "none",
                    border: "none",
                    background: "transparent",
                    width: "100%",
                    color: "white"
                }
            },
            "& .contact-list-container": {
                display: "flex",
                flexDirection: "column",
                flex: 1,
                overflowY: "auto",
                "& .contact-header": {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    "& > p": {
                        margin: 0,
                        "&:nth-child(1)": {
                            fontSize: "0.75rem",
                            fontWeight: 500,
                            color: "white"
                        },
                        "&:nth-child(2)": {
                            fontSize: "0.875rem",
                            fontWeight: 400,
                            color: "#F37F78",
                            cursor: "pointer"
                        }
                    }
                },
                "& .contacts": {
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "1rem",
                    gap: "0.5rem",
                    flex: 1,
                    "& .contact": {
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        "& .name": {
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "0.875rem",
                            color: "white",
                            gap: "0.5rem",
                            "& .avatar": {
                                display: "flex",
                                width: "2rem",
                                height: "2rem",
                                justifyContent: "center",
                                alignItems: "center",
                                border: "1px solid #26EFF5",
                                color: "#26EFF5",
                                borderRadius: "100%",
                                textTransform: "capitalize"
                            },
                        },
                        "& > img": {
                            cursor: "pointer"
                        },
                        "&.selected": {
                            opacity: 0.5,
                            cursor: "not-allowed",
                            "& > img": {
                                cursor: "not-allowed"
                            }
                        },
                        "& .user-contact-full-name": {
                            display: "block",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            maxWidth: "258px"
                        }
                    },
                    "& > .no-data-found": {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flex: 1,
                        fontSize: "1rem",
                        color: "white",
                        fontWeight: 400
                    }
                }
            }
        },
        "& .footer": {
            padding: "1rem",
            "& button": {
                outline: "none",
                border: "none",
                background: "linear-gradient(180deg, rgba(243, 127, 120, 0.75) 0%, rgba(247, 83, 103, 0.75) 100%)",
                width: "100%",
                borderRadius: "62.5rem",
                color: "white",
                height: "3rem",
                cursor: "pointer",
                fontSize: "1rem",
                fontWeight: 500,
                letterSpacing: "1px",
                "&:disabled": {
                    opacity: '0.5',
                    cursor: "inherit"
                }
            }
        },
        [`@media screen and (max-width: 600px)`]: {
            width: "80%",
            height: "70%"
        },
    }
})

export default SelectContacts;