import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions");
import { IEventCard, IEventData } from "../../../components/src/CommonTypes";
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  id: string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  token: string;
  events: IEventCard[];
  loading: boolean;
  // Customizable Area End
}
interface SS {}

export default class EventsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getEventsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      token: "",
      loading: false,
      events: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      if(!token){
        token = await helper.getStorageData("authToken");
      }
      this.setState({
        token: token
      },()=>{
        this.getEvents()
      })
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if(webApiRequestCallId === this.getEventsApiCallId){
        this.handleGetEventsApiCall(message)
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  getEvents = async ()=>{
    helper.showLoader();
    this.setState({
      loading: true
    })
    this.getEventsApiCallId = await helper.apiCall({
      method: "GET",
      endPoint: `${webConfigJSON.upcomingEventsAPIEndpoint}`,
      contentType: webConfigJSON.dashboardContentType,
      token: this.state.token
    })
  }
  handleGetEventsApiCall = (message: Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(responseJson && responseJson.data){
      const cardsList = responseJson.data.map((item: IEventData) => {
        const cardObj = {
          id: item.id,
          image: item.attributes.image_url || "",
          isTrending: item.attributes.is_trending ? true : false,
          location: item.attributes.location?.address || "",
          price: item.attributes.ticket_price ? `₹${item.attributes.ticket_price}` : "",
          time: helper.formatDate(`${item.attributes.date_time_event} ${item.attributes.start_time_event}`, "DD MMM, ddd hh:mm A"),
          title: item.attributes.title,
        };
        return cardObj;
      });
      this.setState({
        events: cardsList
      })
    }else{
      this.setState({
        events: []
      })
    }
    helper.hideLoader();
    this.setState({
      loading: false
    })
  }

  

  redirectTo = (endpoint: string, params?:{[key:string]:string | number}) => {
    this.props.navigation.navigate(endpoint, params)
  }

  goBack = ()=>{
    this.props.navigation.goBack()
  }
  // Customizable Area End
}
