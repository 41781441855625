// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
import { IAppointmentData, IEventCard, IEventData } from "../../../components/src/CommonTypes";
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions");
import moment from "moment";
import { IGroupCard, IReminderCard } from "./types";
import { IReminderItem } from "../../tasks/src/types";
import { IGroup } from "../../groups/src/types";
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    searchOptions: {
        [key: string]: {
            label: string;
            selected: boolean;
            value: string;
        }
    };
    searchQuery: string;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    token: string | null;
    appointments: IEventCard[];
    events: IEventCard[];
    reminders: IReminderCard[];
    groups: IGroupCard[];
    appointmentsLoading: boolean;
    eventsLoading: boolean;
    remindersLoading: boolean;
    groupsLoading: boolean;
    // Customizable Area End
}

export interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class GlobalSearchController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getAppointmentsApiCallId: string = "";
    getEventsApiCallId: string = "";
    getRemindersApiCallId: string = "";
    getGroupsApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            appointments: [],
            events: [],
            reminders: [],
            groups: [],
            appointmentsLoading: false,
            eventsLoading: false,
            remindersLoading: false,
            groupsLoading: false
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        await super.componentDidMount();
        this.setToken();
        // Customizable Area Start
        // Customizable Area End
    }

    setToken = async () => {
        const token = await helper.getStorageData("authToken");

        if (token) {
            this.setState({
                token
            }, () => {
                this.getSearchedData();
            })
        }
    };

    receive = async (from: string, message: Message) => {
        // Customizable Area Start
        const apiCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        switch (apiCallId) {
            case this.getAppointmentsApiCallId:
                this.handleGetAppointmentsApiCall(message);
                break;
            case this.getEventsApiCallId:
                this.handleGetEventsApiCall(message);
                break;
            case this.getRemindersApiCallId:
                this.handleGetRemindersApiCall(message);
                break;
            case this.getGroupsApiCallId:
                this.handleGetGroupsApiCall(message);
                break;
        }
        // Customizable Area End
    };

    // Customizable Area Start
    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
        const {
            searchQuery
        } = this.props;
        if (searchQuery !== prevProps.searchQuery) {
            this.getSearchedData();
        }
    }

    getSearchedData = async () => {
        const {
            searchOptions,
            searchQuery
        } = this.props;
        helper.showLoader();
        const {
            token
        } = this.state;
        Object.values(searchOptions).filter(option => option.selected).forEach(async (option) => {
            switch (option.value) {
                case "appointments":
                    this.setState({
                        appointmentsLoading: true
                    })
                    this.getAppointmentsApiCallId = await helper.apiCall({
                        method: "GET",
                        endPoint: `${configJSON.getGlobalSearchApiEndPoint}?search_for=appointments&query=${searchQuery}`,
                        contentType: configJSON.dashboardContentType,
                        token: token
                    })
                    break;
                case "events":
                    this.setState({
                        eventsLoading: true
                    })
                    this.getEventsApiCallId = await helper.apiCall({
                        method: "GET",
                        endPoint: `${configJSON.getGlobalSearchApiEndPoint}?search_for=events&query=${searchQuery}`,
                        contentType: configJSON.dashboardContentType,
                        token: token
                    })
                    break;
                case "reminders":
                    this.setState({
                        remindersLoading: true
                    })
                    this.getRemindersApiCallId = await helper.apiCall({
                        method: "GET",
                        endPoint: `${configJSON.getGlobalSearchApiEndPoint}?search_for=reminders&query=${searchQuery}`,
                        contentType: configJSON.dashboardContentType,
                        token: token
                    })
                    break;
                case "groups":
                    this.setState({
                        groupsLoading: true
                    })
                    this.getGroupsApiCallId = await helper.apiCall({
                        method: "GET",
                        endPoint: `${configJSON.getGlobalSearchApiEndPoint}?search_for=groups&query=${searchQuery}`,
                        contentType: configJSON.dashboardContentType,
                        token: token
                    })
                    break;
            }
        })
    }

    handleGetAppointmentsApiCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson && responseJson.search && responseJson.search.length) {
            const cardData = responseJson.search.map((item: IAppointmentData) => {
                const cardObj = {
                    id: item.id,
                    image: "",
                    isTrending: false,
                    location: "",
                    price: "",
                    time: this.formatEventCardsTime(item.attributes.appointment_date, item.attributes.start_time),
                    title: item.attributes.title,
                };
                return cardObj;
            })
            this.setState({
                appointments: cardData
            })
        } else {
            this.setState({
                appointments: []
            })
        }
        this.setState({
            appointmentsLoading: false
        })
        helper.hideLoader()
    }

    handleGetEventsApiCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson && responseJson.search && responseJson.search.length) {
            const cardData = responseJson.search.map((item: IEventData) => {
                const cardObj = {
                    id: item.id,
                    image: item.attributes.image_url || "",
                    isTrending: !!item.attributes.is_trending,
                    location: item.attributes.location?.city || "",
                    price: `₹${item.attributes.ticket_price}`,
                    time: this.formatEventCardsTime(item.attributes.date_time_event, item.attributes.start_time_event),
                    title: item.attributes.title,
                };
                return cardObj;
            })
            this.setState({
                events: cardData
            })
        } else {
            this.setState({
                events: []
            })
        }
        this.setState({
            eventsLoading: false
        })
        helper.hideLoader()
    }

    handleGetRemindersApiCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson && responseJson.search && responseJson.search.length) {
            const cardData = responseJson.search.map((item: IReminderItem) => {
                const cardObj: IReminderCard = {
                    id: item.id,
                    time: this.formatEventCardsTime(item.attributes.date_reminder, item.attributes.time_reminder),
                    title: item.attributes.title,
                    description: item.attributes.description
                };
                return cardObj;
            })
            this.setState({
                reminders: cardData
            })
        } else {
            this.setState({
                reminders: []
            })
        }
        this.setState({
            remindersLoading: false
        })
        helper.hideLoader()
    }

    handleGetGroupsApiCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson && responseJson.search && responseJson.search.length) {
            const cardData = responseJson.search.map((item: {attributes: IGroup}) => {
                const cardObj: IGroupCard = {
                    id: item.attributes.id,
                    title: item.attributes.name,
                    type: item.attributes.group_type,
                    is_trending: !!item.attributes.is_trending,
                    image_url: item.attributes.image_url
                };
                return cardObj;
            })
            this.setState({
                groups: cardData
            })
        } else {
            this.setState({
                groups: []
            })
        }
        this.setState({
            groupsLoading: false
        })
        helper.hideLoader()
    }

    formatEventCardsTime = (date: string, time: string) => {
        const dateTime = moment(date + " " + time);
        return dateTime.format("DD MMM, ddd hh:mm A");
    }

    redirectTo = (endpoint: string, params?: { [key: string]: string | number }) => {
        this.props.navigation.navigate(endpoint, params)
    }

    // Customizable Area End
}