import React, { useState, useEffect } from "react";
import { styled, Typography, Dialog, Tooltip } from "@material-ui/core";
import { ClockIcon, LocationIcon, closeIcon, ChatsIcon, EditIcon, DeleteIcon } from "../assets";
import { MeetingType } from "../AddAppointmentController";
const { formateDate, copyLink, getUsers, formatLocation } = require("../ApiCallFunction");
import { Appointment as AppointmentType, UserContact } from "../AppointmentsController";
import ContactList from "./ContactList.web";
import MorePopUp from "../../../../components/src/MorePopUp.web";
const { HelperFunctions } = require("../../../../components/src/HelperFunctions");
import InvitedUser from "./InvitedUser.web";
import RsvpStatus from "../../../events/src/components/RsvpStatus.web";
const { HelperFunctions: helper } = require("../../../../components/src/HelperFunctions")
import { Avatar, Button } from "../../../../components/src";

interface AppointmentProps {
    redirectTo?: (destination: string, params?: { [key: string]: string | number }) => void;
    appointment: AppointmentType;
    onUpdateRsvpStatus: (status: string, appointmentId: string | number) => void;
    setInvitedUsers: (users: UserContact[], owner_id: number, invitation_members?: {[key: string]: string}[]) => void;
    onRsvpUpdateModeChange: () => void;
    activeTab: string | null;
    closeUserListModal: () => void;
    setActiveTab: (tab: string | null) => void;
    isRsvpStatusUpdateMode: boolean;
    invitedUserList: {
        owner_id: number;
        invited_users: UserContact[];
    } | null;
    optionDropdownMenuAnchorEle?: HTMLElement | null,
    openOptionMenu?: (event: React.MouseEvent<HTMLDivElement>) => void
    openDeleteModal?: (meetingTile: string, appointmentId?: string) => void;
    isListView?: boolean;
    userID?: number | null;
}

const Appointment: React.FC<AppointmentProps> = (props: AppointmentProps) => {
    const { appointment, onUpdateRsvpStatus, setInvitedUsers, onRsvpUpdateModeChange, activeTab, closeUserListModal, setActiveTab, isRsvpStatusUpdateMode, invitedUserList, redirectTo, openDeleteModal, isListView = false, userID=null } = props;
    const [isLinkCopied, setCopyLink] = useState<boolean>(false)
    const [userId, setUserId] = useState<number | null>(userID)
    const copyLinkHandler = (meeting_link: string) => {
        copyLink(meeting_link);
        setCopyLink(true)
        setTimeout(() => {
            setCopyLink(false)
        }, 10000)
    }

    const maxLengthOfPostBody = 270;
    const [isExpandedAppointmentDesc, setIsExpandedAppointmentDesc] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const toggleReadMore = () => {
        setIsExpandedAppointmentDesc(!isExpandedAppointmentDesc);
    };

    const displayText = () => {
        const body = appointment?.attributes?.description || '';

        if (isExpandedAppointmentDesc) {
            return body;
        }

        const isTruncated = body.length > maxLengthOfPostBody;
        const truncatedText = body.substring(0, maxLengthOfPostBody);

        return isTruncated ? `${truncatedText}...` : truncatedText;
    }

    const InvitedUsesList = (userList: { owner_id: number, invited_users: UserContact[], invitation_members?: {[key: string]: string}[] } | null) => {
        return (
            <Dialog 
                open={!!userList}
                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                onClose={closeUserListModal}
                PaperProps={{
                    style: { background: "transparent", boxShadow: "none" }
                }}
            >
                <StyledInvitedUsersList id="invited-users-modal" className="Appointment_StyledInvitedUsersList">
                    <div className="header">
                        <p>Invited Persons</p>
                        <img 
                         src={closeIcon} 
                         alt="close.svg" 
                         onClick={()=>{
                            closeUserListModal();
                         }} 
                        />
                    </div>
                    <div className="body">
                        <div className="tabs">
                            <div className={`tab ${activeTab === null ? "active" : ""}`} onClick={() => setActiveTab(null)}>All</div>
                            <div className={`tab ${activeTab === "accepted" ? "active" : ""}`} onClick={() => setActiveTab("accepted")}>Yes</div>
                            <div className={`tab ${activeTab === "rejected" ? "active" : ""}`} onClick={() => setActiveTab("rejected")}>No</div>
                        </div>
                        <div className="contact-list">
                            {
                                userList && userList.invited_users.filter(user => (activeTab === null || activeTab === user.rsvp_status)).length > 0 && userList.invited_users.filter(user => (activeTab === null || activeTab === user.rsvp_status)).map(user => {
                                    return (
                                        <div className="contact" key={user.id}>
                                            <div>
                                                <Avatar
                                                    text={user.name[0]}
                                                    image_url={user.image_url}
                                                    status={user.rsvp_status}
                                                />
                                                <div className="user-details">
                                                    <div>
                                                        {user.name}
                                                        {
                                                            userList.owner_id === user.id && (
                                                                <span>{" "}(Organizer)</span>
                                                            )
                                                        }
                                                    </div>
                                                    <div>{user.email ? user.email : user.full_phone_number}</div>
                                                </div>
                                            </div>
                                            <div>
                                                <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.35925 7.99933L1.082 1.72208C0.916407 1.55648 0.83574 1.35937 0.840004 1.13074C0.844282 0.902106 0.929219 0.704996 1.09482 0.539412C1.26041 0.373815 1.45752 0.291016 1.68615 0.291016C1.91477 0.291016 2.11189 0.373815 2.27748 0.539412L8.6573 6.93204C8.80793 7.08268 8.91958 7.25147 8.99223 7.43843C9.06487 7.6254 9.10119 7.81237 9.10119 7.99933C9.10119 8.18629 9.06487 8.37325 8.99223 8.56022C8.91958 8.74718 8.80793 8.91598 8.6573 9.06662L2.26467 15.4592C2.09907 15.6248 1.90409 15.7055 1.67973 15.7012C1.45539 15.697 1.26041 15.612 1.09482 15.4464C0.929219 15.2808 0.846421 15.0837 0.846421 14.8551C0.846421 14.6265 0.929219 14.4294 1.09482 14.2638L7.35925 7.99933Z" fill="white" fill-opacity="0.7" />
                                                </svg>
                                            </div>
                                        </div>
                                    )
                                })    
                            }
                            {
                                activeTab === null && userList && userList.invitation_members && userList.invitation_members.filter(user=> user.status === "pending").map(user=>{
                                    return (
                                       <InvitedUser user={user} key={user.id}/>
                                    )
                                })
                            }
                            {
                                userList && userList.invited_users.filter(user => (activeTab === null || activeTab === user.rsvp_status)).length === 0 && (
                                    <div className="no-invited-person-appointment">
                                        <Typography component={"span"}>No invited persons found</Typography>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </StyledInvitedUsersList>
            </Dialog>
        )
    }

    const isPastDate = helper.isPastDate(`${appointment.attributes.appointment_date} ${appointment.attributes.start_time}`);

    const menuItems = [
        {
            label: "Chats",
            icon: ChatsIcon,
            onClick: () => {
            }
        },
        {
            label: "Edit",
            icon: EditIcon,
            onClick: () => {
                if (redirectTo) {
                    redirectTo("EditAppointment", { id: appointment.id })
                }
            }
        },
        {
            label: "Delete",
            icon: DeleteIcon,
            onClick: () => {
                if (openDeleteModal) {
                    openDeleteModal(appointment.attributes.title, appointment.id)
                }
            }
        },
    ]

    const handleOngoingEventTitleClick = (appointmentId: string) => {
        if(isListView) {
            redirectTo?.("AppointmentDetails",{ id: appointmentId })
        }
    }

    useEffect(() => {
        (async ()=> {
            const userData = await HelperFunctions.getUserData();
            if (userData) {
                setUserId(userData.id)
            }
        })();
    }, [])
    return (
        <StyledNextAppointmentContainer className="Appointment_StyledNextAppointmentContainer">
            <div className="next-appointment">
                <div className="header">
                    <Typography id="appointment-title" component={"span"} className={`header-title capital-text ${isListView ? "ongoing-app-header" : ""}`} onClick={() => handleOngoingEventTitleClick(appointment.id)}>{appointment.attributes.title}</Typography>
                    {
                        (userId && userId === appointment.attributes.account_id && appointment.attributes.status !== "cancelled" && !(helper.isPastDate(`${appointment.attributes.appointment_date} ${appointment.attributes.end_time}`))) && (
                            <MorePopUp
                                menuItems={menuItems}
                            />
                        )
                    }
                </div>
                <div className="time-and-location">
                    <div>
                        <img src={ClockIcon} />
                        {`${formateDate(appointment.attributes.appointment_date, "DD MMMM dddd")}, ${appointment.attributes.start_time} - ${appointment.attributes.end_time}`}
                    </div>
                    {
                        appointment.attributes.meeting_type === MeetingType.Offline && (
                            <div className="location">
                                <img src={LocationIcon} /> 
                                <Tooltip placement="top" title={formatLocation(appointment.attributes.location)}>
                                <span className="ellipsis">
                                    {formatLocation(appointment.attributes.location)} 
                                    </span>
                                    </Tooltip>
                            </div>
                        )
                    }
                    {
                        appointment.attributes.meeting_type === MeetingType.Online && (
                            <div className="online-link">
                                <span className="ellipsis">{appointment.attributes.meeting_link}</span>
                                <span className="copy-btn" onClick={() => copyLinkHandler(appointment.attributes.meeting_link)}>
                                    {
                                        isLinkCopied ? "Copied" : "Copy link"
                                    }
                                </span>
                            </div>
                        )
                    }
                </div>
                <div className="appointment-details">
                    <div className="hosted-by" style={{ width: "20%" }}>
                        <div className="header">Hosted By</div>
                        <div className="details">
                            <div className="avatar">{appointment.attributes.created_by[0].toUpperCase()}</div>
                            <span 
                            data-test-id="user-name"
                            style={isHovered ? { ...styles.name, ...styles.hovered } : styles.name}
                              onMouseEnter={() => setIsHovered(true)}
                              onMouseLeave={() => setIsHovered(false)}
                              >{isHovered ? appointment.attributes.created_by : `${appointment.attributes.created_by}`}</span>
                              
                        </div>
                    </div>
                    <div className="guest-list" style={{ flex: 1 }}>
                        <div className="header">
                            {
                             appointment.attributes.invitation_members ? (appointment.attributes.user_contacts.length + appointment.attributes.invitation_members.length): appointment.attributes.user_contacts.length
                            }
                            {" "} 
                            Guests
                        </div>
                        <div className="list">
                            <div className="details">
                                <div className="no-of-people">
                                    <p>{getUsers("accepted", appointment.attributes.user_contacts)}</p>
                                    <p>Going</p>
                                </div>
                                <div className="no-of-people">
                                    <p>
                                        {
                                            appointment.attributes.invitation_members ? (getUsers(null, appointment.attributes.user_contacts) + appointment.attributes.invitation_members.length) : getUsers(null, appointment.attributes.user_contacts)
                                        }
                                    </p>
                                    <p>Awaited</p>
                                </div>
                                <div className="no-of-people">
                                    <p>{getUsers("rejected", appointment.attributes.user_contacts)}</p>
                                    <p>Not Going</p>
                                </div>
                            </div>
                            <div className="contact-list">
                                <div className="divider"></div>
                                <ContactList 
                                  list={appointment.attributes.user_contacts} 
                                  owner_id={appointment.attributes.account_id}
                                  invited_users={appointment.attributes.invitation_members} 
                                  setInvitedUsers={
                                    (list: UserContact[], owner_id: number)=>{
                                        setInvitedUsers(list, owner_id, appointment.attributes.invitation_members);
                                    }
                                  } 
                                />
                            </div>
                        </div>
                    </div>
                    {appointment.attributes.status !== "cancelled" &&<div className="rsvp-status">
                        {
                            !(appointment.attributes.rsvp_status === null && isPastDate) && (
                                <RsvpStatus
                                    rsvp_status={appointment.attributes.rsvp_status}
                                    unique_id={appointment.id}
                                    isRsvpStatusUpdateMode={isRsvpStatusUpdateMode}
                                    onRsvpUpdateModeChange={onRsvpUpdateModeChange}
                                    onUpdateRsvpStatus={onUpdateRsvpStatus}
                                    disabled={isPastDate}
                                />
                            )
                        }
                    </div>}
                </div>
               {appointment.attributes.description && <div className="description">
                    <header>Description</header>
                    <p>
                        {displayText()}
                    </p>
                    {appointment?.attributes?.description?.length > maxLengthOfPostBody && <Button onClick={toggleReadMore} type="link" buttonId="appointment_detail_description" text={isExpandedAppointmentDesc ? 'Show Less' : 'Show More'} buttonStyles={{ fontSize: "16px", padding: "0px", height: "auto" }} />}
                </div>}
            </div>
            {InvitedUsesList(invitedUserList)}
        </StyledNextAppointmentContainer>
    )
}

const StyledNextAppointmentContainer = styled("div")({
    "&.Appointment_StyledNextAppointmentContainer": {
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        boxSizing: "border-box",
        "& > header": {
            fontSize: "1rem",
            fontWeight: 500,
            color: "#FFFFFF",
            "& span": {
                fontSize: "1rem",
                fontWeight: 500,
                color: "#FFFFFF",
            }
        },
        "& .next-appointment": {
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
            padding: "1rem 3rem 1rem 1rem",
            borderRadius: "1rem",
            boxSizing: "border-box",
            "& .header": {
                fontSize: "1.25rem",
                fontWeight: 600,
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                "& .header-title": {
                    fontSize: "1.25rem",
                    fontWeight: 600,
                    color: "white",
                },
                "& .ongoing-app-header": {
                    cursor: "pointer",
                    "&:hover": {
                        textDecoration: "underline"
                    }
                },
            },
            "& .time-and-location": {
                display: "flex",
                gap: "2rem",
                boxSizing: "border-box",
                "& > div": {
                    display: "flex",
                    gap: "0.5rem",
                    padding: "0rem 1rem",
                    backgroundColor: "#00000033",
                    height: "2.3125rem",
                    alignItems: "center",
                    color: "white",
                    borderRadius: "0.5rem",
                    boxSizing: "border-box",
                    overflow: "hidden",
                    fontSize: "0.875rem",
                    fontWeight: 400,
                    "&:first-child": {
                        flexShrink: 0,
                        fontWeight: 500
                    },
                    "&::not(:first-child)": {
                        whiteSpace: "nowrap"
                    }
                },
                "& .online-link": {
                    justifyContent: "space-between",
                    gap: "0.8rem",
                    "& .copy-btn": {
                        whiteSpace: "nowrap",
                        color: "#F75367",
                        cursor: "pointer"
                    }
                }
            },
            "& .appointment-details": {
                display: "flex",
                "& .hosted-by": {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: "0.8rem",
                    "& .header": {
                        fontSize: "0.875rem",
                        color: "#FFFFFFCC",
                        fontWeight: 500
                    },
                    "& .details": {
                        display: "flex",
                        alignItems: "center",
                        gap: "0.5rem",
                        color: "#ffffff",
                        "& .avatar": {
                            width: "2rem",
                            height: "2rem",
                            border: "1px solid #25ECF2",
                            borderRadius: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#25ECF2"
                        }
                    }
                },
                "& .guest-list": {
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    "& .header": {
                        fontSize: "0.875rem",
                        color: "#FFFFFFCC",
                        fontWeight: 500
                    },
                    "& .details": {
                        display: "flex",
                        gap: "4.5rem",
                        "& .no-of-people": {
                            display: "flex",
                            flexDirection: "column",
                            textWrap: "no-wrap",
                            "& p": {
                                margin: 0,
                                textWrap: "no-wrap",
                                "&:first-child": {
                                    fontSize: "1rem",
                                    color: "white"
                                },
                                "&:last-child": {
                                    color: "#FFFFFFCC",
                                    fontSize: "0.75rem"
                                }
                            }
                        },
                        [`@media screen and (max-width: 1160px)`]: {
                            gap: "0.5rem"
                        },
                        [`@media screen and (max-width: 550px)`]: {
                            flexWrap: "wrap"
                        },
                        [`@media screen and (max-width: 1350px)`]: {
                            gap: "2rem"
                        },
                    },
                    "& .contact-list": {
                        display: "flex",
                        gap: "2.25rem",
                        alignItems: "center",
                        "& .divider": {
                            height: "100%",
                            width: "1px",
                            backgroundColor: "#FFFFFF73",
                            opacity: 0.1
                        },
                        [`@media screen and (max-width: 1270px)`]: {
                            gap: "1rem"
                        },
                    },
                    "& .list": {
                        display: "flex",
                        gap: "2.25rem"
                    },
                    [`@media screen and (max-width: 1270px)`]: {
                        "& .list": {
                            gap: "1rem"
                        }
                    },
                },
                "& .rsvp-status": {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: "0.5rem",
                    "& .header": {
                        fontSize: "0.875rem",
                        color: "#FFFFFFCC",
                        fontWeight: 500
                    },
                    "& .details": {
                        display: "flex",
                        gap: "1rem",
                        "& .status": {
                            display: "flex",
                            alignItems: "center",
                            gap: "0.3rem",
                            color: "white",
                            fontSize: "0.875rem",
                            fontWeight: 400
                        },
                        "& button": {
                            border: "none",
                            outline: "none",
                            backgroundColor: "transparent",
                            color: "#F75367",
                            fontSize: "1rem",
                            fontWeight: 500,
                            cursor: "pointer"
                        }
                    }
                },
            },
            "& .description": {
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                "& > header": {
                    fontSize: "0.875rem",
                    fontWeight: 500,
                    color: "#FFFFFFCC",
                    margin: 0
                },
                "& > p": {
                    margin: 0,
                    fontSize: "1rem",
                    fontWeight: 400,
                    color: "rgba(255, 255, 255, 1)",
                    wordBreak: "break-word"
                }
            },
            [`@media screen and (max-width: 1160px)`]: {
                padding: "1rem"
            },
            [`@media screen and (max-width: 940px)`]: {
                "& .appointment-details": {
                    flexDirection: "column",
                    gap: "1rem",
                    "& .hosted-by": {
                        width: "100% !important"
                    }
                },
                "& .time-and-location": {
                    flexDirection: "column",
                    "& > div:first-child": {
                        height: "3rem",
                        wordBreak: "break-all",
                        width: "fit-content"
                    },
                }
            },
        }
    }
})

const StyledUpdateRsvpStatus = styled("div")({
    "&.Appointment_StyledUpdateRsvpStatus": {
        display: "flex",
        gap: "1rem",
        "& > button": {
            outline: "none",
            border: "1px solid #F75367 !important",
            fontSize: "1rem",
            fontWeight: "500",
            color: "#F75367",
            width: "5.3125rem",
            height: "2.25rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "0.5rem",
            borderRadius: "62.5rem"
        }
    }
})

const StyledInvitedUsersList = styled("div")({
    "&.Appointment_StyledInvitedUsersList": {
        display: "flex",
        flexDirection: "column",
        width: "26.25rem",
        maxWidth: "95%",
        height: "34.3125rem",
        maxHeight: "80%",
        backgroundColor: "#23404B",
        boxShadow: "0px 0px 20px 0px #00000040",
        borderRadius: "1rem",
        "& .header": {
            position: "relative",
            padding: "1rem",
            borderBottom: "1px solid #2d4953",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "& p": {
                margin: 0,
                fontSize: "1.125rem",
                fontWeight: 500,
                color: "white"
            },
            "& img": {
                position: "absolute",
                right: "1rem",
                top: "50%",
                transform: "translate(-50%,-50%)",
                cursor: "pointer"
            }
        },
        "& .body": {
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            overflowY: "auto",
            padding: "1rem",
            "& .tabs": {
                alignSelf: "center",
                background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
                border: "1px solid rgba(25, 181, 185, 0.27)",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                borderRadius: "3.125rem",
                padding: "0.25rem 0.25rem",
                boxSizing: "border-box",
                "& .tab": {
                    height: "1.75rem",
                    width: "2.625rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "0.875rem",
                    fontWeight: 400,
                    color: "rgba(255, 255, 255, 1)",
                    borderRadius: "3.125rem",
                    cursor: "pointer",
                    transition: "all 0.3s ease-in-out",
                    "&.active": {
                        background: "linear-gradient(90deg, #FF7F77 0%, #FF6376 100%)",
                        fontWeight: 500
                    }
                }
            },
            "& .contact-list": {
                display: "flex",
                flexDirection: "column",
                "& .contact": {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottom: "1px solid #2d4953",
                    padding: "0.5rem 0rem",
                    "&:last-child": {
                        borderBottom: "none"
                    },
                    "& > div": {
                        display: "flex",
                        gap: "0.5rem",
                        alignItems: "center",
                        "& .avatar": {
                            width: "2rem",
                            height: "2rem",
                            border: "1px solid #25ECF2",
                            borderRadius: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#25ECF2",
                            position: "relative",
                            zIndex: 1,
                            fontSize: "0.875rem",
                            textTransform: "uppercase",
                            "& .icon": {
                                width: "0.75rem",
                                height: "0.75rem",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "absolute",
                                right: 0,
                                bottom: 0,
                                transform: "translate(25%,25%)",
                                backgroundColor: "#00FF7F",
                                borderRadius: "100%",
                                zIndex: 4,
                                "&.not-going": {
                                    backgroundColor: "#FF2F3F"
                                }
                            },
                            "&.extra": {
                                background: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
                                color: "white",
                                border: "1px solid #F75367"
                            }
                        },
                        "& .user-details": {
                            "& > div": {
                                color: "#ffffff",
                                fontSize: "1rem",
                                fontWeight: 500,
                                "& span": {
                                    fontSize: "0.875rem",
                                    fontWeight: 400,
                                    color: "rgba(255, 255, 255, 0.8)"
                                },
                                "&:last-child": {
                                    fontSize: "0.875rem",
                                    color: "rgba(255, 255, 255, 0.8)",
                                    fontWeight: 400
                                }
                            }
                        }
                    }
                }
            },
            "& .no-invited-person-appointment": {
                padding: "120px 0px",
                textAlign: "center",
                "& span": {
                    fontSize: "16px",
                    color: "#FFFFFF",
                    fontWeight: 500,
                }
            }
        }
    }
})

const styles = {
    name: {
      width: '150px',              
      overflow: 'hidden',          
      textOverflow: 'ellipsis',    
      cursor: 'pointer',           
      display: 'inline-block',     
      transition: 'all 0.3s',       
    },
    hovered: {
      overflow: 'visible',        
      wordWrap: 'break-word',
    }
  };

export default Appointment;