// Customizable Area Start
import React from "react";
import RepliesListController, { Props } from "./RepliesListController";
import { Box, CircularProgress, Menu, Typography, styled } from "@material-ui/core";
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions");
import { MoreIcon, likeIcon } from "../../activityfeed/src/assets";
import FavoriteIcon from '@material-ui/icons/Favorite';
import Avatar from "../../activityfeed/src/components/Avatar.web";

// Customizable Area End

export default class RepliesList extends RepliesListController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    RepliesListMenuOptionDropdown = () => {
        const { onDeleteReply } = this.props;
        const {
            optionReplyMenuAnchor,
            selectedReplyItem
        } = this.state;
        return (
            <CustomCommentListMenuOptions
                className="Custom_List_Menu_Reply"
                data-test-id="custom-list-menu-reply"
                open={Boolean(optionReplyMenuAnchor)}
                onClose={this.closeRepliesListOptionMenu}
                anchorEl={optionReplyMenuAnchor}
                PaperProps={{
                    style: {
                        transform: "translateY(0.5rem)"
                    }
                }}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                getContentAnchorEl={null}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top',
                }}
            >
                <StyledRepliesListMenuOptionDropdown id="group-post-comments-reply-options-menu" className="RepliesListMenuOptionDropdown_Web">
                    <Box id="delete-group-post-comments-reply-icon" className="reply-menu-item"
                        onClick={() => {
                            if (onDeleteReply) {
                                if (selectedReplyItem) {
                                    onDeleteReply(selectedReplyItem.id);
                                }
                                this.closeRepliesListOptionMenu()
                            }
                        }}
                    >
                        <svg id="delete-group-post-comments-reply-icon-svg" width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.3077 17.5017C2.80898 17.5017 2.38302 17.3251 2.02982 16.9719C1.67661 16.6187 1.5 16.1928 1.5 15.694V3.00176H0.5V1.50179H4.99997V0.617188H11V1.50179H15.5V3.00176H14.5V15.694C14.5 16.1992 14.325 16.6267 13.975 16.9767C13.625 17.3267 13.1974 17.5017 12.6922 17.5017H3.3077ZM13 3.00176H2.99997V15.694C2.99997 15.7838 3.02883 15.8575 3.08652 15.9152C3.14422 15.9729 3.21795 16.0018 3.3077 16.0018H12.6922C12.7692 16.0018 12.8397 15.9697 12.9038 15.9056C12.9679 15.8415 13 15.771 13 15.694V3.00176ZM5.40385 14.0018H6.90382V5.00176H5.40385V14.0018ZM9.09613 14.0018H10.5961V5.00176H9.09613V14.0018Z" fill="white" />
                        </svg>
                        Delete
                    </Box>
                </StyledRepliesListMenuOptionDropdown>
            </CustomCommentListMenuOptions>
        )
    }

    RepliesList = () => {
        const {
            repliesList,
            isRepliesLoading,
            userId,
            replyMetaData,
            focusOnReplyTextfield,
        } = this.props;
        const { optionReplyMenuAnchor } = this.state;
        return (
            <StyledGroupPostCommentsReplies className="Group_Post_Comments_Replies_Container">
                <Box className="group-comments-reply-body">
                    <div id="group-post-comments-reply-top-div"></div>
                    {
                        repliesList && repliesList.length > 0 && (
                            <Box className="group-comments-reply-container">
                                {
                                    repliesList.map((reply) => {
                                        return (
                                            <Box key={reply.id} className="group-post-comments-reply">
                                                <Box className="group-post-comments-reply-details">
                                                    <Box className="name">
                                                        <Avatar
                                                            text={reply.attributes.account_info.full_name}
                                                            image_url={reply.attributes.account_info.image_url}
                                                        />
                                                        <p>
                                                            {
                                                                reply.attributes.account_info.full_name
                                                            }
                                                            <span>{reply.attributes.raw_created_at}</span>
                                                        </p>

                                                    </Box>
                                                    {
                                                        userId === reply.attributes.account_info.id && (
                                                            <div>
                                                                <Box id="delete-group-post-comments-reply-option-icon" style={{
                                                                    cursor: "pointer"
                                                                }} onClick={event => {
                                                                    this.openRepliesListOptionMenu(event);
                                                                    this.setState({ selectedReplyItem: reply })
                                                                }}>
                                                                    <img src={MoreIcon} />
                                                                </Box>
                                                                {optionReplyMenuAnchor && this.RepliesListMenuOptionDropdown()}
                                                            </div>
                                                        )
                                                    }
                                                </Box>
                                                <Box className="group-post-comments-reply-text">{helper.replaceUsernamesWithStyling(reply.attributes.descripation, this.handleReplyGoToMentionedProfile)}</Box>
                                                <Box className="comment-like-replies-info-box" display={"flex"}>
                                                    <Box className="comment-like cursor-pointer">
                                                        <Typography onClick={() => this.props.fetchLikedUsersList?.(reply.id)} id="reply-liked-users" className="text-underline" component={"span"}>{reply.attributes.likes_count || 0} {reply.attributes.likes_count > 1 ? "Likes" : "Like"}.</Typography>
                                                        {reply.attributes.liked_by_me ? <FavoriteIcon onClick={() => this.props.handleLikeDislikeReply?.(reply.id)} className="liked_by_me" /> : <img id="like-reply" onClick={() => this.props.handleLikeDislikeReply?.(reply.id)} src={likeIcon} alt="like" />}
                                                    </Box>
                                                    <Box className="comment-reply">
                                                        <Typography className="cursor-pointer" onClick={focusOnReplyTextfield} component={"span"}>| Reply</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        )
                                    })
                                }
                            </Box>
                        )
                    }
                    {
                        replyMetaData?.next_page &&
                        <Box onClick={this.props.handleFetchNextPageReplies} className="more-replies">Load more replies</Box>
                    }
                    {
                        (!isRepliesLoading && repliesList?.length) === 0 && (
                            <Box className="no-replies">No replies found</Box>
                        )
                    }
                </Box>
                {isRepliesLoading &&
                    <Box className="replies-circular-progress-loader">
                        <CircularProgress />
                    </Box>
                }
            </StyledGroupPostCommentsReplies>
        );
    };

    // Customizable Area End

    render() {
        // Customizable Area Start

        return (
            <>
                {this.RepliesList()}
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const StyledGroupPostCommentsReplies = styled("div")({
    "&.Group_Post_Comments_Replies_Container": {
        minHeight: "4rem",
        maxHeight: "170px",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        "& .group-comments-reply-body": {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            overflowX: "auto",
            gap: "2px",
            padding: "3px",
            "& .group-comments-reply-container": {
                display: "flex",
                flexDirection: "column",
                "& .group-post-comments-reply": {
                    display: "flex",
                    flexDirection: "column",
                    padding: "0.5rem 0rem",
                    "& .group-post-comments-reply-details": {
                        gap: "0.5rem",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        "& .name": {
                            alignItems: "center",
                            display: "flex",
                            gap: "0.5rem",
                            "& > p": {
                                fontSize: "0.75rem",
                                fontWeight: 400,
                                color: "rgba(255, 255, 255, 1)",
                                "& > span": {
                                    fontSize: "0.5rem",
                                    color: "rgba(255, 255, 255, 0.8)",
                                    marginLeft: "0.5rem"
                                }
                            }
                        }
                    },
                    "& .group-post-comments-reply-text": {
                        fontWeight: 400,
                        fontSize: "0.875rem",
                        marginLeft: "2.5rem",
                        color: "rgba(255, 255, 255, 1)",
                    },
                    "& .comment-like-replies-info-box": {
                        marginLeft: "2.5rem",
                        alignItems: "center",
                        marginTop: "10px",
                        gap: "8px",
                        "& div": {
                            alignItems: "center",
                            display: "flex",
                            gap: "2px",
                            "& span": {
                                color: "rgba(255, 255, 255, 0.8)",
                                fontSize: "12px",
                            },
                            "& img": {
                                width: "12px",
                                height: "12px",
                            },
                            "& svg": {
                                width: "18px",
                                height: "18px",
                                color: "red"
                            }
                        }
                    },
                    "&:last-child": {
                        borderBottom: "none"
                    }
                }
            },
            "& .no-replies": {
                alignItems: "center",
                display: "flex",
                color: "white",
                fontSize: "0.875rem",
                fontWeight: "400",
                justifyContent: "center",
                flex: 1
            },
            "& .more-replies": {
                color: "rgba(255, 255, 255, 1)",
                fontWeight: "500",
                fontSize: "12px",
                cursor: "pointer",
            }
        },
        "& .footer": {
            padding: "1rem",
            borderTop: "1px solid #2d4953",
            display: "flex"
        },
        "& .replies-circular-progress-loader": {
            position: "absolute",
            top: 0,
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            "& .MuiCircularProgress-colorPrimary": {
                color: "#F75367 !important"
            }
        }
    }
})

const StyledRepliesListMenuOptionDropdown = styled("div")({
    "&.RepliesListMenuOptionDropdown_Web": {
        display: "flex",
        border: "1px solid #19B5B945",
        flexDirection: "column",
        width: "9.8125rem",
        borderRadius: "0.5rem",
        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        padding: "0.25rem 0rem",
        "& .reply-menu-item": {
            display: "flex",
            fontSize: "1rem",
            color: "#ffffff",
            fontWeight: 400,
            gap: "0.5rem",
            alignItems: "center",
            padding: "0.25rem 0.7rem",
            cursor: "pointer",
            backgroundColor: "transparent",
            transition: "background-color 0.3s ease-in-out",
            "&:hover": {
                backgroundColor: "#0000001A"
            }
        }
    }
})

const CustomCommentListMenuOptions = styled(Menu)({
    "&.Custom_List_Menu_Reply": {
        "& .MuiList-padding": {
            padding: 0,
        },
        "& .MuiPaper-root": {
            background: "transparent"
        }
    }
})
// Customizable Area End
