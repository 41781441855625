// Customizable Area Start
import React from "react";
import ExploreController, {
    Props,
    groupData
} from "./ExploreController";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { styled } from "@material-ui/styles";
import { Button, CardScroll, PostCard, CustomNoDataComponent } from "../../../components/src";
import { FilterIcon } from "./assets";
import ExploreCard from "./components/ExploreCard.web";
import DetectLocation from "../../events/src/components/DetectLocation.web";
// Customizable Area End

export default class Explore extends ExploreController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    ExploreHeader = () => {
        const {
          filterAnchorEle,
          recentLocations,
          predictions,
          selectedNearByLocationCoordinates,
          interests,
          currentSelectedInterest,
          selectedInterest,
          filters
        } = this.state;
        return (
            <StyledExploreHeader className="Explore_StyledExploreHeader">
                <p>Explore</p>
                <Button
                    text="Filter"
                    icon={FilterIcon}
                    type="outlined"
                    buttonStyles={{ width: "5.9375rem" }}
                    onClick={this.openFilterPopup}
                />
                <DetectLocation 
                  anchorEle={filterAnchorEle}
                  onClose={this.closeFilterPopup}
                  recentLocations={recentLocations}
                  predictions={predictions}
                  onSearchLocation={this.onSearchLocation}
                  onNearByLocationSelectGetCoordinates={this.getLocationCoordinates}
                  selectedNearByLocationCoordinates={selectedNearByLocationCoordinates}
                  isSelectInterestVisible={true}
                  interestList={interests}
                  onSubmitNewInterest={this.onSubmitNewInterest}
                  selectedInterest={selectedInterest}
                  currentSelectedInterest={currentSelectedInterest}
                  onAddInterest={this.onAddInterest}
                  filter={filters}
                  onApplyFilter={this.applyFilter}
                />
            </StyledExploreHeader>
        )
    }
    BestEvents = () => {
        const {
            bestEvents
        } = this.state;
        return (
            <StyledBestEventsContainer className="Explore_StyledBestEventsContainer">
                <div className="header">
                    <div className="left">
                        <header>The Best Event </header>
                        <p>Monday To Sunday we got you covered </p>
                    </div>
                    <div className="right">
                        <Button
                            text="View All"
                            type="link"
                            onClick={() => this.redirectTo("BestEvents")}
                        />
                    </div>
                </div>
                {
                    bestEvents && bestEvents.length > 0 && (
                        <div className="events-container">
                            <CardScroll
                                prevStyles={{
                                    transform: "translate(-5%, -50%)"
                                }}
                                nextStyles={{
                                    transform: "translate(50%, -50%)"
                                }}
                            >
                                {
                                    bestEvents.map(event => {
                                        return (
                                            <div className="event-card" key={event.id} onClick={() => this.redirectTo("EventDetail", { eventId: event.id })}>
                                                <ExploreCard
                                                    key={event.id}
                                                    id={event.id}
                                                    image={event.image}
                                                    title={event.title}
                                                    time={event.time}
                                                    location={event.location.address as string}
                                                    type="event"
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </CardScroll>
                        </div>
                    )
                }
                {
                    bestEvents.length === 0 && (
                        <div className="no-data">
                            <CustomNoDataComponent
                                titleMsg={"No Trending Events"}
                                subTitleMsg={"No scheduled and trending events. Stay tuned for the updates."}
                            />
                        </div>
                    )
                }
            </StyledBestEventsContainer>
        )
    }

    Groups = () => {
        return (
            <StyledBestEventsContainer className="Explore_StyledBestEventsContainer">
                <div className="header">
                    <div className="left">
                        <header>The Best Groups</header>
                    </div>
                    <div className="right">
                        <Button
                            text="View All"
                            type="link"
                        />
                    </div>
                </div>
                {
                    groupData && groupData.length > 0 && (
                        <div className="events-container">
                            <CardScroll
                                prevStyles={{
                                    transform: "translate(-4%, -50%)"
                                }}
                                nextStyles={{
                                    transform: "translate(50%, -50%)"
                                }}
                            >
                                {
                                    groupData.map(group => {
                                        return (
                                            <div className="event-card" key={group.id}>
                                                <ExploreCard
                                                    key={group.id}
                                                    id={group.id}
                                                    image={""}
                                                    title={group.title}
                                                    description={group.description}
                                                    type="event"
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </CardScroll>
                        </div>
                    )
                }
            </StyledBestEventsContainer>
        )
    }

    TrendingPostContainer = () => {
        const {
          trendingPost,
          commentsList,
          isLoadingCommentsList,
          userId,
          likedUserList
        } = this.state;
        return (
            <StyledTrendingPostContainer className="Explore_StyledTrendingPostContainer">
                <div className="header">
                    <header>Trending Post</header>
                    <Button text="View All" type="link" buttonId="view_all_trending_posts" buttonStyles={{fontSize: "14px"}} onClick={() => this.redirectTo("TrendingPostsList", { type: trendingPost?.post?.data?.attributes.event_id ? "event" : "group", id: trendingPost?.post?.data?.attributes.event_id || trendingPost?.post?.data?.attributes.group_id || "" })} />
                </div>
                {
                    trendingPost?.post?.data && (
                        <div className="trending-posts show-scrollbar">
                            <PostCard
                                key={trendingPost.post.data.id}
                                userId={userId}
                                post={{
                                    id: trendingPost.post.data.id,
                                    createdBy: trendingPost.post.data.attributes.account_info.full_name,
                                    createdAt: trendingPost.post.data.attributes.created_at,
                                    title: trendingPost.post.data.attributes.trending_post_name,
                                    type: trendingPost.post.data.attributes.event_id ? "event" : "group",
                                    postText: trendingPost.post.data.attributes.body,
                                    likeCount: trendingPost.post.data.attributes.total_likes,
                                    commentCount: trendingPost.post.data.attributes.comments_count,
                                    images_and_videos: trendingPost.post.data.attributes.images_and_videos,
                                    liked_by_me: trendingPost.post.data.attributes.liked_by_me
                                }}
                                commentsList={commentsList}
                                onClickComment={this.onOpenComments}
                                isLoadingCommentsList={isLoadingCommentsList}
                                handleFetchNextComments={this.getNextComments}
                                onCloseCommentModal={this.onCloseComments}
                                onAddComment={this.onAddPostComment}
                                onDeleteComment={this.onDeletePostComment}
                                onOpenLikedUserListModal={this.onOpenLikedUserList}
                                onCloseLikedUserListModal={this.onCloseLikedUserList}
                                likedUserList={likedUserList}
                                onLikePost={this.onLikePost}
                            />
                        </div>
                    )
                }
            </StyledTrendingPostContainer>
        )
    }

    ExploreContainer = () => {
        return (
            <StyledExploreContainer className="Explore_StyledExploreContainer">
                {this.ExploreHeader()}
                <div className="explore-container">
                    <div className="left">
                        {this.BestEvents()}
                        {this.Groups()}
                    </div>
                    <div className="right">
                        {this.TrendingPostContainer()}
                    </div>
                </div>
            </StyledExploreContainer>
        )
    }

    // Customizable Area End

    render() {
        // Customizable Area Start

        return (
            <NavigationMenu {...this.props} children={this.ExploreContainer()} />
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const StyledExploreContainer = styled("div")({
    "&.Explore_StyledExploreContainer": {
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem",
        padding: "1rem 1.5rem 0rem",
        height: "100%",
        boxSizing: "border-box",
        "& > .explore-container": {
            display: "flex",
            height: "calc(100% - 4.5rem)",
            overflow: "auto",
            "& > .left": {
                display: "flex",
                width: "calc(100% - 24.4375rem)",
                padding: "0rem 1rem 0rem 0rem",
                boxSizing: "border-box",
                flexDirection: "column",
                gap: "1rem",
                flex: "0 0 auto"
            },
            "& > .right": {
                width: "24.4375rem",
                height: "100%",
                background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
                borderRadius: "1rem",
                padding: "1rem",
                boxSizing: "border-box",
                flex: 1,
                minHeight: "40rem"
            }
        }
    }
})
const StyledExploreHeader = styled("div")({
    "&.Explore_StyledExploreHeader": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& > p": {
            fontSize: "1.25rem",
            fontWeight: 500,
            color: "rgba(255, 255, 255, 1)"
        }
    }
})
const StyledBestEventsContainer = styled("div")({
    "&.Explore_StyledBestEventsContainer": {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        "& > .header": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            "& > .left": {
                display: "flex",
                flexDirection: "column",
                "& > header": {
                    fontSize: "1rem",
                    fontWeight: 500,
                    color: "rgba(255, 255, 255, 1)"
                },
                "& > p": {
                    fontSize: "0.75rem",
                    fontWeight: 400,
                    color: "rgba(255, 255, 255, 1)"
                }
            }
        },
        "& .events-container": {
            width: "100%",
            display: "flex",
            marginTop: "1rem",
            "& .event-card": {
                display: "flex",
                flexShrink: 0,
                marginRight: "0.5rem",
                width: "12.0625rem",
                height: "14.75rem",
                "&:last-child": {
                    marginRight: 0
                }
            }
        }
    }
})
const StyledTrendingPostContainer = styled("div")({
    "&.Explore_StyledTrendingPostContainer": {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        "& > .header": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            "& > header": {
                fontSize: "0.875rem",
                fontWeight: 500,
                color: "rgba(255, 255, 255, 0.8)"
            }
        },
        "& > .trending-posts": {
            marginTop: "1rem",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            overflow: "auto"
        }
    }
})
// Customizable Area End
