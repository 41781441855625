import React from "react";
import { styled } from "@material-ui/core";
import EventCard from "./Card.web";
import { EVENT_TYPES } from "../../../../components/src/CommonTypes";
import { CardScroll, CustomNoDataComponent } from "../../../../components/src";

interface CardScrollWrapperProps {
    cardsList: {
        id: string | number,
        image: string,
        title: string,
        time: string,
        location: {
            id: number;
            latitude: number;
            longitude: number;
            van_id: number | null;
            address: string | null;
            locationable_type: string;
            locationable_id: number;
            place_id: string;
            country: string;
            state: string;
            city: string;
        };
        price: string,
        isTrending: boolean
    }[];
    typeOfEvent: string;
    redirectTo: (endpoint: string, params?: { [key: string]: string | number }) => void;
    showNoDataMessage?: boolean;
    type?: "appointment"
}


const CardScrollWrapper: React.FC<CardScrollWrapperProps> = (props: CardScrollWrapperProps) => {
    const { cardsList, typeOfEvent, redirectTo, showNoDataMessage = true, type } = props;
    
    return (
        <StyledCardScrollWrapper id="card-scroll-container" className="StyledCardScrollWrapper_Web">
            {
                cardsList && cardsList.length > 0 && (
                    <CardScroll
                        prevStyles={{
                            transform: "translate(-50%, -50%)"
                        }}
                        nextStyles={{
                            transform: "translate(50%, -50%)"
                        }}
                    >
                        {
                            cardsList.map((card) => {
                                return (
                                    <div onClick={() => redirectTo("EventDetail", { eventId: card.id })} key={card.id} className='card-container'>
                                        <EventCard type={type} id={card.id} title={card.title} image={card.image} location={card.location} time={card.time} isTrending={card.isTrending} />
                                    </div>
                                )
                            })
                        }
                    </CardScroll>
                )
            }

            {
                cardsList.length === 0 && showNoDataMessage &&
                <div className="no-data">
                    <CustomNoDataComponent titleMsg={typeOfEvent === EVENT_TYPES.UPCOMING ? "No Upcoming Events" : "No Trending Events"} subTitleMsg={typeOfEvent === EVENT_TYPES.UPCOMING ? "No scheduled events. Stay tuned for the updates." : "No scheduled and trending events. Stay tuned for the updates."} useCommonEmptyImg={typeOfEvent === EVENT_TYPES.UPCOMING} />
                </div>
            }
        </StyledCardScrollWrapper>
    )
}

const StyledCardScrollWrapper = styled("div")({
    "&.StyledCardScrollWrapper_Web": {
        position: "relative",
        width: "100% !important",
        display: "flex",
        "& .prev,& .next": {
            position: "absolute",
            top: "50%",
            zIndex: 2,
            color: "white",
            cursor: "pointer",
            width: "1.5rem",
            height: "1.5rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(35, 64, 75, 1)",
            borderRadius: "100%"
        },
        "& .next": {
            right: 0,
            transform: "translate(50%,-50%)",
        },
        "& .prev": {
            transform: "translate(-50%,-50%)",
        },
        "& .c-slider": {
            display: "flex",
            width: "100%",
            transition: "transform 0.3s ease-in-out",
            
        },
        "& .card-container": {
            flexShrink: 0,
            marginRight: "1.5rem",
            width: "12.0625rem",
            height: "14.75rem",
            "&:last-child": {
                marginRight: 0
            }
        },
        "& .no-data": {
            width: "100%",
        }
    }
})

export default CardScrollWrapper;