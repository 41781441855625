import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import MomentUtils from "@date-io/moment";

export const ApiCallFunction = async (data) => {
    let { method, endPoint, body, type = "", contentType, token } = data;
    const header = {
      token: token,
      "Content-Type": contentType,
    };
    let apiRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    apiRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    apiRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    apiRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    apiRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && type !== "formData"
      ? apiRequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body))
      : apiRequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
    return apiRequestMessage.messageId;
};

export const formatLocation = (location)=>{
  if(location){
    return location.address;
  }
  return "";
}

export const rearrangeContacts = (account_id, user_contacts)=> {

  // Filter out the contacts with the account_id
  const organizerContacts = user_contacts.filter(contact => contact.id === account_id);
  // Filter out the contacts without the account_id
  const otherContacts = user_contacts.filter(contact => contact.id !== account_id);

  // Combine the organizer contacts at the top with the other contacts
  const rearrangedContacts = [...organizerContacts, ...otherContacts];

  return rearrangedContacts;
}

export const getUsers = (status, user_contacts)=>{
  const users = user_contacts?.filter((contact)=> contact.rsvp_status === status);
  return users?.length;
}


export const formateDate= (value, format)=>{
  const moment = new MomentUtils();
  const formattedDate = moment.date(value).format(format)
  return formattedDate;
}

export const copyLink = (meetLink)=>{
  navigator?.clipboard?.writeText(meetLink);
}



