// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";
const { ApiCallFunction: apiCallFunction } = require("../../appointmentmanagement/src/ApiCallFunction");
export const configJSON = require("./config.js");
import { getStorageData } from "../../../framework/src/Utilities";
import { IEventCard, IEventData } from "../../../components/src/CommonTypes";
const { HelperFunctions } = require("../../../components/src/HelperFunctions");
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    token: string | null;
    bestEventsList: IEventCard[];
    // Customizable Area End
}

export interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class BestEventsListController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getBestEventsListApiId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            bestEventsList: [],
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        await super.componentDidMount();
        this.getToken();
        // Customizable Area Start
        // Customizable Area End
    }

    getToken = () => {
        const message: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(message);
    };

    receive = async (from: string, message: Message) => {
        // Customizable Area Start
        if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            let token = message.getData(getName(MessageEnum.SessionResponseToken));
            if (!token) {
                token = await getStorageData("authToken");
            }
            this.setToken(token);
        }

        const apiCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            if(apiCallId === this.getBestEventsListApiId){
                this.handleGetBestEventsCall(message);
            }
        }
        // Customizable Area End
    };

    // Customizable Area Start
    formatBestEventCardsTime = (date: string, time: string) => {
        const dateTime = moment(date + " " + time);
        return dateTime.format("DD MMM, ddd hh:mm A");
    }

    handleGetBestEventsCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (!responseJson?.errors && responseJson?.data?.length) {
            const trendingCardsList = responseJson.data.map((item: IEventData) => {
                const trendingCardObj = {
                    id: item.id,
                    image: item.attributes?.image_url || "",
                    isTrending: false,
                    location: item?.attributes?.location?.address || "",
                    price: `₹${item.attributes?.ticket_price}`,
                    time: this.formatBestEventCardsTime(item.attributes.date_time_event, item.attributes.start_time_event),
                    title: item.attributes.title,
                };
                return trendingCardObj;
            });
            this.setState({
                bestEventsList: trendingCardsList,
            })
        } else {
            this.setState({
                bestEventsList: [],
            })
        }
        HelperFunctions.hideLoader();
    }

    setToken = async (token: string | null) => {
       this.setState({ token }, () => {
        this.getBestEventsList();
       })
    }

    getBestEventsList = async () => {
        HelperFunctions.showLoader();
        this.getBestEventsListApiId = await apiCallFunction({
            method: configJSON.exampleAPiMethod,
            contentType: configJSON.validationApiContentType,
            endPoint: `${configJSON.bestEventsEndpoint}`,
            token: this.state.token
        })
    }

    redirectTo = (endpoint: string, params?: { [key: string]: string | number }) => {
        this.props.navigation.navigate(endpoint, params)
    }

    // Customizable Area End
}