// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
import { getStorageData } from "../../../framework/src/Utilities";
const { ApiCallFunction: apiCallFunction } = require("../../appointmentmanagement/src/ApiCallFunction");
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions");
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    token: string | null;
    contactName: string;
    contactNameError: string;
    contactEmail: string;
    contactEmailError: string;
    issueDescription: string;
    issueDescriptionError: string;
    currentFocusedInputTextField: number | undefined;
    // Customizable Area End
}

export interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ContactUsNewController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    submitContactUsDetailsApiId?: string;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            contactName: "",
            contactNameError: "",
            contactEmail: "",
            contactEmailError: "",
            issueDescription: "",
            issueDescriptionError: "",
            currentFocusedInputTextField: undefined,
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        await super.componentDidMount();
        this.getToken();
        // Customizable Area Start
        this.setToken();
        // Customizable Area End
    }

    getToken = () => {
        const message: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(message);
    };

    receive = async (from: string, message: Message) => {
        // Customizable Area Start
        const apiCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            if (apiCallId === this.submitContactUsDetailsApiId) {
                this.handleSubmitContactInfoCall(message)
            }
        }
        // Customizable Area End
    };

    // Customizable Area Start

    setToken = async () => {
        this.setState({ token: await helper.getStorageData("authToken") })
    }

    onFocusChange = (index: number | undefined) => {
        this.setState({
            currentFocusedInputTextField: index
        })
    }

    onChangeHandler = (name: string, value: string) => {
        this.setState({
            ...this.state,
            [name]: value,
        })
    }

    isValidEmail(email: string) {
        // Regular expression for validating an Email
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    }

    handleCheckEmailIsValid = () => {
        const { contactEmail } = this.state;
        if (!this.isValidEmail(contactEmail)) {
            this.setState({
                contactEmailError: "*Please enter a valid email",
            })
        }
        this.onFocusChange(undefined);
    }

    onChangeContactEmailHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const trimValue = event.target.value.trim();
        this.setState({
            contactEmail: trimValue,
            contactEmailError: ""
        })
    }

    isSubmitBtnDisabled = () => {
        const { contactEmailError, contactName, issueDescription, contactEmail } = this.state;
        return (contactEmailError || !contactEmail || !contactName || !issueDescription)
    }

    handleSubmitContactInfoCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (!responseJson?.errors && responseJson?.data) {
            this.setState({
                contactEmail: "",
                contactEmailError: "",
                contactName: "",
                contactNameError: "",
                currentFocusedInputTextField: undefined,
                issueDescription: "",
                issueDescriptionError: "",
            });
            helper.showSuccessToast("Your query was submitted successfully!")
        } else {
            const errorMsg = responseJson?.errors?.[0]?.message ? responseJson.errors[0].message : "Something went wrong, please try again!";
            helper.showErrorToast(errorMsg)
        }
        helper.hideLoader()
    }

    submitContactInfo = async () => {
        const { contactEmail, contactName, issueDescription, token } = this.state;
        helper.showLoader();
        const body = {
            "name": contactName,
            "email": contactEmail,
            "description": issueDescription
        };
        this.submitContactUsDetailsApiId = await apiCallFunction({
            method: configJSON.httpPostMethod,
            endPoint: configJSON.contactUsEndpoint,
            contentType: configJSON.contactUsApiContentType,
            body,
            token
        })
    }

    getNumberOfCharactersRemaining = () => {
        const { issueDescription } = this.state;
        return 2048 - issueDescription.length;
    }

    // Customizable Area End
}